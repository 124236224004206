body {
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.container {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 400px;
  width: 100%;
  text-align: center;
  margin: 20px;
}

.container img {
  width: 150px;
}

.container h1 {
  font-size: 24px;
  color: #333;
  margin: 10px 0;
}

.container p {
  font-size: 14px;
  color: #666;
  margin: 5px 0 20px;
}

.feedback-section {
  margin: 20px 0;
}

.feedback-section h2 {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
}

.feedback-section p {
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
}

.rating {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.rating button {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 14px;
  color: #333;
  flex: 1;
  margin: 5px;
}

.rating button.active {
  background-color: #e89953;
  color: #fff;
  border: 1px solid #e89953;
}

select,
input[type="text"] {
  width: 90%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
}

.submit-btn {
  background-color: #e89953;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.resubscribe-section {
  margin-top: 20px;
}

.resubscribe-section button {
  color: #e89953;
  text-decoration: none;
  font-size: 14px;
  display: inline-block;
  margin-top: 10px;
}

.resubscribe-section button.resubscribe-btn {
  background-color: #fff;
  border: 1px solid #e89953;
  border-radius: 5px;
  padding: 10px 20px;
  color: #e89953;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  margin-top: 20px;
}

@media (max-width: 530px) {
  .container {
    max-width: 300px;
    margin-top: 80px;
  }
}

@media (max-width: 380px) {
  .container {
    max-width: 250px;
    margin-top: 150px;
  }
}
